<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h2>News Feed</h2>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="javascript:void(0)"> {{ blogTitle }}</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-8">
              <div class="saasio-blog-details-content">
                <div class="blog-details-img">
                  <img src="assets/images/blogs/crm6.PNG" alt="Un logiciel CRM et statistiques ?" />
                </div>
                <div class="blog-details-text dia-headline">
                  <h1>{{ blogTitle }}</h1>
                  <article>
                    Un logiciel CRM peut également offrir des fonctionnalités de statistiques pour vous aider à mieux comprendre votre activité commerciale et à prendre des décisions éclairées. Voici quelques exemples de statistiques que vous pouvez trouver dans un logiciel CRM :
                  </article>
                  <h3>
                    Performance des ventes :
                  </h3>
                  <article>
                    Vous pouvez suivre les performances de vos ventes, y compris le nombre de ventes, le chiffre d'affaires, le taux de conversion, le cycle de vente moyen, etc.
                  </article>
                  <h3>
                    Comportement des clients :
                  </h3>
                  <article>
                    Vous pouvez analyser les données de vos clients pour comprendre leur comportement, leurs habitudes d'achat, leurs préférences, etc. Vous pouvez également suivre les taux d'attrition des clients pour comprendre pourquoi les clients partent.
                  </article>
                  <h3>
                    Campagnes de marketing :
                  </h3>
                  <article>
                    Vous pouvez suivre les performances de vos campagnes de marketing, y compris les taux d'ouverture des e-mails, les taux de clics, les conversions, etc.
                  </article>
                  <h3>
                    Service client :
                  </h3>
                  <article>
                    Vous pouvez suivre les performances de votre service client, y compris les temps de réponse, les niveaux de satisfaction, les taux de résolution, etc.
                  </article>
                  <article>
                    En utilisant un logiciel CRM pour les statistiques, vous pouvez identifier les tendances, les opportunités et les défis de votre entreprise, et prendre des décisions éclairées en fonction des données. Cela peut vous aider à améliorer votre efficacité commerciale, à maximiser vos bénéfices et à renforcer la relation avec vos clients.
                  </article>
                </div>
                
                <ShareContent />

              </div>
            </div>
            
            <RightSideBar />

          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
import ShareContent from '../components/ShareContent.vue';
import RightSideBar from '../components/RightSideBar.vue';
export default {
    name : 'BlogDetail6',
    components  : {
      ShareContent,
      RightSideBar
    },
    data() {
      return {
        //blogDetail : require('../assets/blogs/crm9.PNG'),
        blogTitle : "Un logiciel CRM et statistiques ?"
      }
    }
};
</script>

<style>
</style>