<template>
    <div class="blog-details-tag clearfix">
        <div class="blog-feed-tag float-left">
        </div>
        <div class="blog-feed-share float-right">
        <span>Share:</span>
        <a href="javascript:void(0)"><i class="fb-bg fab fa-facebook-f"></i></a>
        <a href="javascript:void(0)"><i class="bh-bg fab fa-linkedin"></i></a>
        <a href="javascript:void(0)"><i class="tw-bg fab fa-twitter"></i></a>
        <a href="javascript:void(0)"><i class="dr-bg fab fa-instagram"></i></a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Share',
  props: {
    msg: String
  }
}
</script>