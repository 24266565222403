<template>
    <div class="col-md-4">
        <div class="saasio-blog-sidebar">
        <div class="side-bar-widget">
            <div class="category-widget dia-headline ul-li-block">
            <h3 class="widget-title-2">Post récent</h3>
            <div class="recent-post-area">
                <div class="recent-post-img-text">
                <div class="recent-post-img float-left">
                    <router-link :to="'/C-est-quoi-un-logiciel-CRM'">
                        <img src="assets/images/blogs/crm9.PNG" alt="" />
                    </router-link>
                </div>
                <div class="recent-post-text dia-headline">
                    <h6><router-link :to="'/C-est-quoi-un-logiciel-CRM'">C'est quoi un logiciel CRM ?</router-link></h6>
                    <span class="rec-post-meta"></span>
                </div>
                </div>
                <div class="recent-post-img-text">
                <div class="recent-post-img float-left">
                    <router-link :to="'/Comment-il-peut-me-servire-ce-logiciel-CRM'">
                        <img src="assets/images/blogs/crm3.PNG" alt="" />
                    </router-link>
                </div>
                <div class="recent-post-text dia-headline">
                    <h6><router-link :to="'/Comment-il-peut-me-servire-ce-logiciel-CRM'">Comment il peut me servire ce logiciel CRM ?</router-link></h6>
                    <span class="rec-post-meta"></span>
                </div>
                </div>
                <div class="recent-post-img-text">
                <div class="recent-post-img float-left">
                    <router-link :to="'/Logiciel-crm-pour-gerer-les-factures-et-les-ventes'">
                        <img src="assets/images/blogs/crm1.PNG" alt="" />
                    </router-link>
                </div>
                <div class="recent-post-text dia-headline">
                    <h6><router-link :to="'/Logiciel-crm-pour-gerer-les-factures-et-les-ventes'">Logiciel crm pour gerer les factures et les ventes ?</router-link></h6>
                    <span class="rec-post-meta"></span>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RighSideBar',
  props: {
    msg: String
  }
}
</script>